<template>
  <div class="authentication container">
    <div class="columns is-centered">
      <div class="column is-half">
        <EzLogin></EzLogin>
      </div>
      <div v-if="false" class="column is-half">
        <EzRegister></EzRegister>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import EzLogin from '@/components/authentication/EzLogin.vue';
import EzRegister from '@/components/authentication/EzRegister.vue';

@Options({
  components: {
    EzLogin,
    EzRegister,
  },
})
export default class Authentication extends Vue {
}
</script>
