import { createApp } from 'vue';
import '@/assets/main.scss';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUpload, faArrowRight, faBackward } from '@fortawesome/free-solid-svg-icons';
import VueDatePicker from '@vuepic/vue-datepicker';
import App from './App.vue';
import router from './router';
import '@vuepic/vue-datepicker/dist/main.css';

library.add(faUpload, faArrowRight, faBackward);

createApp(App)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app');
