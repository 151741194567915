
import { Vue } from 'vue-class-component';
import authenticationService from '../../services/authentication.service';
import { authService } from '@/services/auth.service';

export default class EzRegister extends Vue {
  name = '';

  email = '';

  password = '';

  submitted = false;

  loading = false;

  error = '';

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    const { email, password, name } = this;

    // stop here if form is invalid
    if (!(email && password && name)) {
      return;
    }

    this.loading = true;
    try {
      await authService.register(name, email, password);
      if (authenticationService.isLoggedIn) {
        this.$router.push('/');
      } else {
        this.error = 'Email is already taken';
      }
    } catch (error: any) {
      this.error = 'Unknown error on registration';
    }
    this.loading = false;
  }
}
