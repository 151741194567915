import authHeader from '@/helpers/auth-header';
import authenticationService from './authentication.service';
import http from '@/http-common';

class AuthService {
  async login(email: string, password: string): Promise<void> {
    const credentials = { email, password };
    try {
      const response = await http.post('/authentication', credentials);
      if (response.data) {
        authenticationService.login(response.data);
      }
    } catch (e: any) {
      if (e.response.status !== 400) {
        throw e;
      }
    }
  }

  async register(name: string, email: string, password: string): Promise<void> {
    const credentials = { name, email, password };
    try {
      const response = await http.post('/registration', credentials);
      if (response.data) {
        authenticationService.login(response.data);
      }
    } catch (e: any) {
      if (e.response.status !== 409) {
        throw e;
      }
    }
  }
}

export const authService = new AuthService();
