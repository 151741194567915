<template>
  <div>
    <h2 class="subtitle">Register</h2>
    <form @submit.prevent="handleSubmit">
      <div class="field">
        <label class="label" for="name">Name</label>
        <div class="control">
        <input
          type="text"
          v-model="name"
          name="name"
          class="input"
          :class="{ 'is-invalid': submitted && !name }"
        />
        </div>
        <div v-show="submitted && !name" class="help is-danger">
          Name is required
        </div>
      </div>
      <div class="field">
        <label class="label" for="email">Email</label>
        <div class="control">
        <input
          type="text"
          v-model="email"
          name="name"
          class="input"
          :class="{ 'is-invalid': submitted && !email }"
        />
        </div>
        <div v-show="submitted && !email" class="help is-danger">
          Email is required
        </div>
      </div>
      <div class="field">
        <label class="label" htmlFor="password">Password</label>
        <div class="control">
        <input
          type="password"
          v-model="password"
          name="password"
          class="input"
          :class="{ 'is-invalid': submitted && !password }"
        />
        </div>
        <div v-show="submitted && !password" class="help is-danger">
          Password is required
        </div>
      </div>
      <div class="field">
        <button class="button is-primary" :disabled="loading">Register</button>
        <span v-show="loading">
          Loading...
        </span>
      </div>
      <div v-if="error" class="help is-danger">{{ error }}</div>
    </form>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import authenticationService from '../../services/authentication.service';
import { authService } from '@/services/auth.service';

export default class EzRegister extends Vue {
  name = '';

  email = '';

  password = '';

  submitted = false;

  loading = false;

  error = '';

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    const { email, password, name } = this;

    // stop here if form is invalid
    if (!(email && password && name)) {
      return;
    }

    this.loading = true;
    try {
      await authService.register(name, email, password);
      if (authenticationService.isLoggedIn) {
        this.$router.push('/');
      } else {
        this.error = 'Email is already taken';
      }
    } catch (error: any) {
      this.error = 'Unknown error on registration';
    }
    this.loading = false;
  }
}
</script>
