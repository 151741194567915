<template>
  <div class="navbar">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">EZfraze</router-link>
      <a
        role="button"
        class="navbar-burger"
        :class="{'is-active': isMenuActive}"
        v-on:click="onHamburgerClick()"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="nav" class="navbar-menu" v-bind:class="isMenuActive ? 'is-active' : ''">
      <div class="navbar-start" v-on:click="onMenuItemClick()">
        <router-link to="/packs" class="navbar-item">Packs</router-link>
        <router-link to="/categories" class="navbar-item">Categories</router-link>
        <router-link to="/teams" class="navbar-item">Teams</router-link>
        <router-link to="/students" class="navbar-item">Students</router-link>
      </div>
      <div class="navbar-end">
        <button type="button" class="button navbar-item" v-on:click="logout()" v-if="isLoggedIn">
          Log out
        </button>
      </div>
    </div>
  </div>
  <router-view />
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import authenticationService from './services/authentication.service';

export default class App extends Vue {
  isLoggedIn = authenticationService.isLoggedIn;

  isMenuActive = false;

  onMenuItemClick(): void {
    this.isMenuActive = false;
  }

  created(): void {
    authenticationService.isLoggedIn$.subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
      if (!isLoggedIn) {
        this.$router.push('/login');
      }
    });
  }

  onHamburgerClick(): void {
    this.isMenuActive = !this.isMenuActive;
  }

  isLogoutVisible(): boolean {
    return authenticationService.isLoggedIn;
  }

  logout(): void {
    authenticationService.logout();
  }
}
</script>
