import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router';
import authenticationService from '@/services/authentication.service';
import Authentication from '@/views/Authentication.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/packs/:packId/cards',
    name: 'Cards',
    component: () => import(/* webpackChunkName: "cards-chunk" */ '../views/Cards.vue'),
    props: true,
  },
  {
    path: '/packs/:packId/import',
    name: 'Import Cards',
    component: () => import(/* webpackChunkName: "cards-chunk" */ '../views/ImportCards.vue'),
    props: true,
  },
  {
    path: '/',
    redirect: '/packs',
  },
  {
    path: '/packs/',
    name: 'Packs',
    component: () => import(/* webpackChunkName: "packs-chunk" */ '../views/Packs.vue'),
    props: true,
  },
  {
    path: '/categories/',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "categories-chunk" */ '../views/Categories.vue'),
    props: true,
  },
  {
    path: '/teams/',
    name: 'Teams',
    component: () => import(/* webpackChunkName: "teams-chunk" */ '../views/Teams.vue'),
    props: true,
  },
  {
    path: '/students',
    name: 'Students',
    component: () => import(/* webpackChunkName: "students-chunk" */ '../views/Students.vue'),
    props: false,
  },
  {
    path: '/students/:userId/answers',
    name: 'Answers',
    component: () => import(/* webpackChunkName: "answers-chunk" */ '../views/Answers.vue'),
    props: true,
  },
  {
    path: '/authentication',
    name: 'Authentication',
    component: Authentication,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/authentication'];
    const authRequired = !publicPages.includes(to.path);

    if (authRequired && !authenticationService.isLoggedIn) {
      next({ path: '/authentication' });
    } else if (authenticationService.isLoggedIn && to.path === '/authentication') {
      next({ path: '/' });
    } else {
      next();
    }
  },
);

export default router;
