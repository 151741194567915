
import { Vue, Options } from 'vue-class-component';
import EzLogin from '@/components/authentication/EzLogin.vue';
import EzRegister from '@/components/authentication/EzRegister.vue';

@Options({
  components: {
    EzLogin,
    EzRegister,
  },
})
export default class Authentication extends Vue {
}
