import axios, { AxiosInstance } from 'axios';
import authenticationService from './services/authentication.service';

const apiClient: AxiosInstance = axios.create({
  // baseURL: 'http://localhost:6060',
  // baseURL: 'http://ezphrazeteacherapi-env.eba-xp3ug3aw.eu-west-2.elasticbeanstalk.com/',
  // baseURL: 'https://ezphraze.nw.r.appspot.com',
  // TODO: should be extracted to a config service
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    'Content-type': 'application/json',
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      authenticationService.logout();
    }
    return Promise.reject(error);
  },
);

export default apiClient;
