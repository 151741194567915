import Observer from '@/helpers/observer';
import { AuthenticationResponse } from '@/api-domain/authentication-response';

const storageKey = 'ezfraze-teacher';

export class AuthenticationService {
  isLoggedIn$ = new Observer<boolean>(!!localStorage.getItem(storageKey));

  get isLoggedIn(): boolean {
    return !!localStorage.getItem(storageKey);
  }

  get user(): AuthenticationResponse|undefined {
    const serializedUser = localStorage.getItem(storageKey) || '';
    if (!serializedUser) {
      return;
    }
    // TODO: WTF IS THIS ERROR?
    // eslint-disable-next-line consistent-return
    return JSON.parse(serializedUser) as AuthenticationResponse;
  }

  logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem(storageKey);
    this.isLoggedIn$.value = false;
  }

  login(response: AuthenticationResponse): void {
    localStorage.setItem(storageKey, JSON.stringify(response));
    this.isLoggedIn$.value = true;
  }
}

export default new AuthenticationService();
