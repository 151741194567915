export default class Observer<T> {
  /*
    E.g.:
      isLoggedIn$ = new Observer<boolean>(false);
      this.isLoggedIn$.value = true;
      authenticationService.isLoggedIn$.subscribe(isLoggedIn => {
        this.isLoggedIn = isLoggedIn
      });
  */

  // eslint-disable-next-line @typescript-eslint/ban-types
  subscribers: Function[] = [];

  private storedValue: T;

  set value(newValue: T) {
    this.update(newValue);
  }

  constructor(defaultValue: T) {
    this.storedValue = defaultValue;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  subscribe(fn: Function): void {
    this.subscribers.push(fn);
  }

  update(newValue: T): void {
    this.storedValue = newValue;
    this.notifyAll();
  }

  private notifyAll() {
    this.subscribers.forEach((s) => s(this.storedValue));
  }
}
