
import { Vue } from 'vue-class-component';
import authenticationService from '../../services/authentication.service';
import { authService } from '@/services/auth.service';

export default class EzLogin extends Vue {
  email = '';

  password = '';

  submitted = false;

  loading = false;

  error = '';

  async handleSubmit(): Promise<void> {
    this.submitted = true;
    const { email, password } = this;

    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    this.loading = true;
    try {
      await authService.login(email, password);
      if (authenticationService.isLoggedIn) {
        this.$router.push('/');
      } else {
        this.error = 'Invalid email or password';
      }
    } catch (error: any) {
      this.error = 'Unknown error on login';
    }
    this.loading = false;
  }
}
