
import { Vue } from 'vue-class-component';
import authenticationService from './services/authentication.service';

export default class App extends Vue {
  isLoggedIn = authenticationService.isLoggedIn;

  isMenuActive = false;

  onMenuItemClick(): void {
    this.isMenuActive = false;
  }

  created(): void {
    authenticationService.isLoggedIn$.subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
      if (!isLoggedIn) {
        this.$router.push('/login');
      }
    });
  }

  onHamburgerClick(): void {
    this.isMenuActive = !this.isMenuActive;
  }

  isLogoutVisible(): boolean {
    return authenticationService.isLoggedIn;
  }

  logout(): void {
    authenticationService.logout();
  }
}
